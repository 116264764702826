import {
  format,
  differenceInDays,
  differenceInMinutes,
  differenceInHours,
  addDays,
  isBefore,
  isAfter,
  addHours,
  addMinutes,
  set,
} from "date-fns";

export const DISCOUNT_RULES = (locale = "zh-TW") => {
  const result = [
    {
      text: locale === "zh-TW" ? "滿 2 天 9 折" : "2 Days for 10% OFF",
      ms: 2880,
      rate: 0.9,
    },
    {
      text: locale === "zh-TW" ? "滿 3 天 85 折" : "3 Days for 15% OFF",
      ms: 4320,
      rate: 0.85,
    },
    {
      text: locale === "zh-TW" ? "滿 4 天 8 折" : "4 Days for 20% OFF",
      ms: 5760,
      rate: 0.8,
    },
    {
      text: locale === "zh-TW" ? "滿 7 天 7 折" : "7 Days for 30% OFF",
      ms: 10080,
      rate: 0.7,
    },
    {
      text: locale === "zh-TW" ? "滿 15 天 65 折" : "15 Days for 35% OFF",
      ms: 21600,
      rate: 0.65,
    },
    {
      text: locale === "zh-TW" ? "滿 30 天 6 折" : "30 Days for 40% OFF",
      ms: 43200,
      rate: 0.6,
    },
  ];
  return result;
};

export const WEEK_DAYS_I18N = (locale) =>
  locale === "zh-TW"
    ? ["日", "一", "二", "三", "四", "五", "六"]
    : ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const TIME_OPTIONS = [
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
];

export const ORDER_LIMIT_HOURS = 12;

export const getTimeStringsByAddMins = (time, n) => {
  let addedTime = addMinutes(new Date(time), n * 60);
  return [format(addedTime, "yyyy/MM/dd"), format(addedTime, "HH:mm")];
};

export function minsToDhms(ms, locale = "zh-TW") {
  let mins = Number(ms);
  var d = Math.floor(mins / (60 * 24));
  var h = Math.floor((mins % (60 * 24)) / 60);
  var m = Math.floor((mins % (60 * 24)) % 60);

  const dUnit = locale === "zh-TW" ? " 天 " : d === 1 ? " Day " : " Days ";
  const hUnit = locale === "zh-TW" ? " 小時 " : h === 1 ? " Hour " : " Hours ";
  const mUnit = locale === "zh-TW" ? " 分鐘" : m === 1 ? " Min" : " Mins";

  var dDisplay = d > 0 ? d + dUnit : "";
  var hDisplay = h > 0 ? h + hUnit : "";
  var mDisplay = m > 0 ? m + mUnit : "";
  return dDisplay + hDisplay + mDisplay;
}

export function getAvailableCarIdsForNewYear(
  startDate,
  startClock,
  endDate,
  endClock,
  allRangeTimes,
  allCarIds,
  configs
) {
  let result = allCarIds;
  let curTime = new Date(`${startDate} ${startClock}`);
  const endTime = new Date(`${endDate} ${endClock}`);
  while (curTime <= endTime) {
    const date = format(curTime, "yyyy/MM/dd");
    const time = format(curTime, "HH:mm");
    result = result.filter((id) => {
      return !allRangeTimes[date][time].cars.includes(id);
    });
    curTime = addMinutes(curTime, 30);
  }

  const checkCondition = (date, clock, staff = {}, config) => {
    const hour = Number(clock.split(":")[0]);
    const times = [`${hour - 1}:00`, clock, `${hour + 1}:00`];

    if (Object(staff).length) {
      return times.every(
        (time) =>
          (allRangeTimes[date][time]?.guys?.[config.location_id] ?? 0) <
          staff[date]
      );
    } else {
      return times.every(
        (time) =>
          (allRangeTimes[date][time]?.guys?.[config.location_id] ?? 0) <
          config.staff_count
      );
    }
  };

  const filterNonMinDate = (avaiableCars, config, startDate, startTime) => {
    let result = avaiableCars;
    const now = new Date();
    const orderStart = new Date(
      `${startDate.format("YYYY/MM/DD")} ${startTime}`
    );
    const startToNowDiffMins = differenceInMinutes(orderStart, now);

    result = result.filter((car) => {
      return (
        startToNowDiffMins >
        latestOrderTimeWithBusinessTime(
          config?.latest_order_time / 60,
          Number(config?.business_start_hour.split(":")[0]),
          Number(config?.business_end_hour.split(":")[0])
        )
      );
    });
    return result;
  };

  result = result.filter((id) => {
    const config = configs.find((config) => config.car_ids.includes(id));
    let STAFF = {};

    const { latest_order_time, business_start_hour, business_end_hour } =
      config;
    const start_time = new Date(`${startDate} ${startClock}`);

    const carBufferHours = latest_order_time / 60;
    const startTime = addBufferHoursST(
      addHours(start_time, 8),
      carBufferHours,
      Number(business_start_hour.split(":")[0]),
      Number(business_end_hour.split(":")[0])
    );

    if (isBefore(startTime, new Date())) return false;

    switch (config.location_id) {
      case "8b230ca3-2acd-4f0a-a283-0fd82401bf45":
        STAFF = {
          "2024/02/06": 4,
          "2024/02/07": 4,
          "2024/02/08": 2,
          "2024/02/09": 2,
          "2024/02/10": 2,
          "2024/02/11": 2,
          "2024/02/12": 4,
          "2024/02/13": 4,
          "2024/02/14": 4,
          "2024/02/15": 4,
          "2024/02/16": 4,
          "2024/02/17": 4,
          "2024/02/18": 4,
        };
        break;
      case "0441d54f-e261-4ff6-8c7a-aeffeb0f108b":
        STAFF = {
          "2024/02/06": 3,
          "2024/02/07": 3,
          "2024/02/08": 2,
          "2024/02/09": 2,
          "2024/02/10": 2,
          "2024/02/11": 2,
          "2024/02/12": 3,
          "2024/02/13": 3,
          "2024/02/14": 3,
          "2024/02/15": 3,
          "2024/02/16": 3,
          "2024/02/17": 3,
          "2024/02/18": 3,
        };
        break;
      default:
        break;
    }

    const _startDate = startDate.format("YYYY/MM/DD");
    const _endDate = endDate.format("YYYY/MM/DD");

    return (
      checkCondition(_startDate, startClock, STAFF, config) &&
      checkCondition(_endDate, endClock, STAFF, config) &&
      filterNonMinDate([id], config, startDate, startClock).length > 0
    );
  });
  return result;
}

export function getAvailableCarIds(
  startDate,
  startClock,
  endDate,
  endClock,
  allRangeTimes,
  allCarIds
) {
  let result = allCarIds;
  let curTime = new Date(`${startDate} ${startClock}`);
  const endTime = new Date(`${endDate} ${endClock}`);
  while (curTime <= endTime) {
    const date = format(curTime, "yyyy/MM/dd");
    const time = format(curTime, "HH:mm");
    result = result.filter((id) => {
      return !allRangeTimes[date][time].cars.includes(id);
    });
    curTime = addMinutes(curTime, 30);
  }
  return result;
}

export const getAllRangeTimes = (start, end) => {
  let result = {};
  let cur = start;
  while (cur <= end) {
    const date = format(cur, "yyyy/MM/dd");
    const time = format(cur, "HH:mm");
    result = {
      ...result,
      [date]: {
        ...result[date],
        car_disabled: false,
        [time]: {
          disabled: false,
          cars: [],
          guys: {},
          orders: [],
        },
      },
    };
    cur = addMinutes(cur, 30);
  }
  return result;
};

// 開放自己設定營業時間用此
export const addBufferHoursET = (
  end,
  carBufferHours,
  businessStartHour,
  businessEndHour
) => {
  let time = "";
  const endHour = parseInt(format(end, "H"));
  // 如果上班是11:00~04:00
  if (businessStartHour > businessEndHour) {
    // if endHour+carBufferHours超過24點
    if (endHour + carBufferHours >= 24) {
      // 結束時間在下班前
      if (endHour + carBufferHours < businessEndHour + 24) {
        time = addHours(end, carBufferHours);
      } else {
        const nextDay = addDays(end, 1);
        // 結束時間在下班前，但當日時間不足以整理完全
        time = set(nextDay, {
          hours:
            businessStartHour + carBufferHours - (businessEndHour - endHour),
        });
      }
    } else {
      time = addHours(end, carBufferHours);
    }
  } else {
    if (endHour + carBufferHours < businessEndHour) {
      time = addHours(end, carBufferHours);
    } else {
      const nextDay = addDays(end, 1);
      if (endHour >= businessEndHour) {
        // 結束時間超過下班，車子的整理延到隔天
        time = set(nextDay, { hours: businessStartHour + carBufferHours });
      } else {
        // 結束時間在下班前，但當日時間不足以整理完全
        time = set(nextDay, {
          hours:
            businessStartHour + carBufferHours - (businessEndHour - endHour),
        });
      }
    }
  }
  return time;
};

// 開放自己設定營業時間用此
export const addBufferHoursST = (
  start,
  carBufferHours,
  businessStartHour,
  businessEndHour
) => {
  let time = "";
  const startHour = parseInt(format(start, "H"));
  // 如果當日上班時間內可以做好車輛準備
  if (startHour - carBufferHours > businessStartHour) {
    time = addHours(start, -carBufferHours);
  } else {
    // 時間不足需提前做準備
    // 11:00~04:00
    if (businessStartHour > businessEndHour) {
      const countTime =
        businessEndHour - (carBufferHours - (startHour - businessStartHour));
      // 當天凌晨時間不夠準備，提前一天開始準備
      if (countTime < 0) {
        const preDay = addDays(start, -1);
        time = set(preDay, {
          hours: 24 + countTime,
        });
      } else {
        time = set(start, {
          hours: countTime,
        });
      }
    } else {
      const preDay = addDays(start, -1);
      time = set(preDay, {
        hours:
          businessEndHour - (carBufferHours - (startHour - businessStartHour)),
      });
    }
  }
  return time;
};

export const latestOrderTimeWithBusinessTime = (
  latestOrderTime,
  businessStartHour,
  businessEndHour
) => {
  let hours = 0;
  let days = 0;
  const nowHour = parseInt(format(new Date(), "H"));
  let latestOrderTimeDiffDays = latestOrderTime;

  if (latestOrderTime > businessEndHour - businessStartHour) {
    days = Math.floor(latestOrderTime / (businessEndHour - businessStartHour));
    latestOrderTimeDiffDays =
      latestOrderTime - days * (businessEndHour - businessStartHour);
  }

  // 如果現在時間超過營業時間
  if (nowHour > businessEndHour || nowHour < businessStartHour) {
    // 下班時間後24點前
    if (nowHour > businessEndHour) {
      hours = 24 - nowHour + businessStartHour + latestOrderTimeDiffDays;
    }
    // 24點後上班時間前
    if (nowHour < businessStartHour) {
      hours = businessStartHour - nowHour + latestOrderTimeDiffDays;
    }
  } else {
    if (businessEndHour - nowHour > latestOrderTimeDiffDays) {
      hours = latestOrderTimeDiffDays;
    } else {
      hours =
        24 + latestOrderTimeDiffDays + businessStartHour - businessEndHour;
    }
  }

  return hours * 60 + days * 24 * 60;
};

export const getDatePickerSettingsByConfigs = (configs, delivery_zones) => {
  let latestOrderTime = Infinity;
  let timeOpts = [];
  let cityList = [];

  configs.forEach((config) => {
    // 取出最快下訂時間
    if (config.latest_order_time < latestOrderTime)
      latestOrderTime = config.latest_order_time;

    // 取出所有可行時間
    const start = config.business_start_hour.split(":")[0];
    const end = config.business_end_hour.split(":")[0];
    if (start > end) {
      for (let i = start; i < 24; i++) {
        let time = String(i).padStart(2, "0");
        if (!timeOpts.includes(time)) timeOpts.push(time);
      }
      for (let i = 0; i <= end; i++) {
        let time = String(i).padStart(2, "0");
        if (!timeOpts.includes(time)) timeOpts.push(time);
      }
    } else {
      for (let i = start; i <= end; i++) {
        let time = String(i).padStart(2, "0");
        if (!timeOpts.includes(time)) timeOpts.push(time);
      }
    }
    const primaryList = config.support_zones_primary?.ids;
    const secondaryList = config.support_zones_secondary?.ids;

    if (cityList.length === 0) {
      if (primaryList) {
        cityList = [...primaryList];
      }
      if (secondaryList) {
        cityList = [...new Set([...cityList, ...secondaryList])];
      }
    } else {
      if (primaryList) {
        cityList = [...new Set([...cityList, ...primaryList])];
      }
      if (secondaryList) {
        cityList = [...new Set([...cityList, ...secondaryList])];
      }
    }
  });

  let deliveryZones = delivery_zones.filter((item) =>
    cityList.includes(item.id.toString())
  );

  let deliveryZonesObj = {};
  deliveryZones.map((item) => {
    if (!deliveryZonesObj[item.level_1]) {
      deliveryZonesObj[item.level_1] = [item];
    } else {
      deliveryZonesObj[item.level_1] = [
        ...deliveryZonesObj[item.level_1],
        item,
      ];
    }
  });
  Object.keys(deliveryZonesObj).map((country) => {
    let level2 = deliveryZonesObj[country];
    let deliveryZonesObj2 = {};
    level2.map((item) => {
      if (!deliveryZonesObj2[item.level_2]) {
        deliveryZonesObj2[item.level_2] = [item];
      } else {
        deliveryZonesObj2[item.level_2] = [
          ...deliveryZonesObj2[item.level_2],
          item,
        ];
      }
    });
    deliveryZonesObj[country] = deliveryZonesObj2;
  });

  return {
    latestOrderTime,
    timeOpts: timeOpts.sort((a, b) => a - b),
    deliveryZonesObj,
  };
};
